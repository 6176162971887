import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'

const Behandling = ({ data }) => (
  <Layout pageTitle='Behandling'>
    <oma-grid-row>
      <div className='section'>
        <h1 className='heading page__heading'>Behandlingsmetoder</h1>
        <div className='image-grid'>
          <GatsbyImage
            image={data.treatment4.childImageSharp.gatsbyImageData}
          />
          <GatsbyImage
            image={data.treatment5.childImageSharp.gatsbyImageData}
          />
          <GatsbyImage
            image={data.treatment6.childImageSharp.gatsbyImageData}
          />
          <GatsbyImage
            image={data.treatment7.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
    </oma-grid-row>
    <oma-grid-row>
      <div className='section'>
        <p className='section__text'>
          Naprapati definieras som ett system för specifik undersökning,
          diagnostik, manuell behandling och rehabilitering av smärta och
          nedsatt funktion i kroppens rörelse- och stödjeorgan.
        </p>
        <p className='section__text'>
          En central del av naprapatin är den manuella behandlingen, dvs.
          behandling där naprapaten främst använder sina händer. Den
          naprapatiska behandlingen innebär att manuella behandlingstekniker
          kombineras på olika sätt i syfte att påverka muskulatur, bindväv,
          leder och nerver så att kroppens funktion blir så bra som möjligt och
          att eventuella besvär minskar. De grundläggande behandlingstekniker
          som naprapaten använder är manipulations- och mobiliseringstekniker av
          såväl ryggraden som andra leder samt olika typer av mjukdelstekniker
          såsom massage, stretching, nervmobilisering m.fl.
        </p>
        <p className='section__text'>
          Naprapaten fäster stor vikt vid att analysera och åtgärda de
          bakomliggande orsakerna till patientens besvär. Det kan exempelvis
          innebära att ta reda på om patientens arbetsställning är fel eller om
          problemet ligger i att muskler och leder inte tränats eller använts på
          optimalt sätt. Behandlingen går även ut på att förebygga besvär hos
          patienten genom att lägga upp individuella träningsprogram och ge råd
          om vad patienten kan förändra för att minska risken för återkommande
          besvär. Naprapaten har också tillräckliga medicinska kunskaper för att
          bedöma när naprapati inte är det behandlingsalternativ som är bäst för
          patienten, och när patienten bör remitteras till annan vårdgivare.
        </p>
        <p className='section__text'>
          Källa:{' '}
          <a className='link' href='https://naprapathogskolan.se'>
            Naprapathögskolan i Stockholm
          </a>
        </p>
      </div>
    </oma-grid-row>
  </Layout>
)

export const treatmentImage = graphql`
  fragment treatmentImage on File {
    childImageSharp {
      gatsbyImageData(width: 280, height: 360, quality: 85, layout: CONSTRAINED)
    }
  }
`

export const query = graphql`
  query {
    treatment4: file(relativePath: { eq: "treatment4.jpg" }) {
      ...treatmentImage
    }
    treatment5: file(relativePath: { eq: "treatment5.jpg" }) {
      ...treatmentImage
    }
    treatment6: file(relativePath: { eq: "treatment6.jpg" }) {
      ...treatmentImage
    }
    treatment7: file(relativePath: { eq: "treatment7.jpg" }) {
      ...treatmentImage
    }
  }
`

export default Behandling
